import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {

  constructor() { }

  alertNotification(tipo:SweetAlertIcon, mensaje:string){
    Swal.fire({
      position: 'center',
      icon: tipo,
      title: mensaje,
      showConfirmButton: false,
      timer: 3000
    })
  }

  alertNotificationWithTime(tipo:SweetAlertIcon, mensaje:string, time:number){
    Swal.fire({
      position: 'center',
      icon: tipo,
      title: mensaje,
      showConfirmButton: false,
      timer: time
    })
  }

  // Método para cerrar todas las alertas activas de SweetAlert
  closeAllAlerts() {
    Swal.close(); // Cierra todas las alertas activas en la pantalla
  }

   // Método ajustado para mostrar la alerta con botón de "Aceptar"
   alertSessionExpired() {
    Swal.fire({
      icon: 'error', // Tipo de alerta (error, warning, info, etc.)
      title: 'La sesión ha caducado',
      text: 'Vuelve a iniciar sesión', // Mensaje personalizado adicional
      confirmButtonText: 'Aceptar', // Botón de confirmación
      allowOutsideClick: false // Evita que se cierre la alerta al hacer clic fuera de ella
    });
  }

  // Método ajustado para preguntar al usuario si desea mantener la sesión activa
  alertAskToContinueSession(): Promise<any> {
    return Swal.fire({
      title: '¿Desea mantener la sesión activa?',
      text: 'Su sesión está por expirar, por favor seleccione una opción.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'SI',
      cancelButtonText: 'NO',
      allowOutsideClick: false // No permitir cerrar haciendo clic fuera
    });
  }

  // Alerta de contraseña expirada
  alertPasswordExpired() {
    Swal.fire({
      icon: 'error', // Tipo de alerta (error, warning, info, etc.)
      title: 'Su contraseña ha expirado',
      text: 'Al dar en Aceptar, continuará con el proceso de cambio de contraseña ', // Mensaje personalizado 
      confirmButtonText: 'Aceptar', // Botón de confirmación
      allowOutsideClick: false // Evita que se cierre la alerta al hacer clic fuera de ella
    });
  }
}
