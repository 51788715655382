import { Component, OnInit } from '@angular/core';
import { NovedadesService } from './novedades.service';
import {  HttpParams, HttpClient  } from '@angular/common/http';
import { environment } from 'environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-novedades',
  templateUrl: './novedades.component.html',
  styleUrls: ['./novedades.component.scss']
})
export class NovedadesComponent implements OnInit {
  rows = []; 
  pagedRows = [];
  currentPage = 0; 
  public contentHeader: object;
  selectedOption = 10;
  limitOptions = [
    { key: '10', value: 10 },
    { key: '20', value: 20 },
    { key: '50', value: 50 }
  ];
  searchValue = '';

  constructor(private novedadesService: NovedadesService, private http: HttpClient) {}

  ngOnInit(): void {
    this.loadNovedades();
    this.initContentHeader();
  }

  initContentHeader(){
    this.contentHeader = {
      headerTitle: 'Novedades',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
        ]
      }
    };

    
  }

  eliminarNovedad(id: string) {
    const url = `${environment.apiAdminService}/novedades/${id}`;
  
    this.http.delete(url).pipe(
      tap(() => {
        // No es necesario verificar `response` ya que recibimos 204 No Content
        console.log('Novedad eliminada con éxito');
        window.location.reload(); // Recargar la página después de la eliminación
      }),
      catchError(error => {
        console.error('Error al eliminar la novedad:', error);
        return this.handleError(error);
      })
    ).subscribe();  // Suscripción al observable para que se ejecute
  }  

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'Ocurrió un error inesperado';
  
    if (error.error instanceof ErrorEvent) {
      // Error del lado del cliente
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Error del lado del servidor
      errorMessage = `Error código: ${error.status}, mensaje: ${error.message}`;
    }
  
    console.error(errorMessage);
    return throwError(errorMessage);
  }

  loadNovedades() {
    this.novedadesService.getNovedades().subscribe(
      (response) => {
        // Ordenar los datos por fecha en orden descendente y asignar a `rows`
        this.rows = response.sort((a, b) => new Date(b.fecha).getTime() - new Date(a.fecha).getTime());
        // Actualizar la primera página de `pagedRows`
        this.updatePagedRows();
      },
      (error) => {
        console.error('Error al obtener las novedades:', error);
      }
    );
  }

  descargarArchivo(nombreArchivo: string, comercioId: string) {
    const params = new HttpParams()
      .set('nombreArchivo', nombreArchivo)
      .set('comercioId', comercioId);
  
    this.http.get(`${environment.apiGenerateUrl}/novedades/download`, {
      params,
      responseType: 'text'
    }).subscribe(
      (url: string) => {
        window.open(url, '_blank'); // Abre la URL en una nueva ventana/pestaña
      },
      (error) => {
        console.error('Error al obtener la URL de descarga', error);
      }
    );
  }
  
  

  updatePagedRows() {
    const startIndex = this.currentPage * this.selectedOption;
    const endIndex = startIndex + this.selectedOption;
    this.pagedRows = this.rows.slice(startIndex, endIndex);
  }

  changePageSize(event: any) {
    this.selectedOption = event;
    this.currentPage = 0; // Reiniciar a la primera página
    this.updatePagedRows(); // Actualizar los datos visibles
  }

  onPageChange(event: any) {
    this.currentPage = event.page - 1; // Angular usa índices basados en 0
    this.updatePagedRows();
  }
}
