import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'tableros',
    title: 'Tableros',
    type: 'section',
    children: [
      {
        id: 'estadisticas',
        title: 'Estadísticas',
        translate: 'MENU.SAMPLE',
        type: 'item',
        icon: 'bar-chart',
        url: 'estadisticas'
      },
    ]
  },
  {
    id: 'consultas',
    title: 'Consultas',
    type: 'section',
    children: [
      {
        id: 'historialProcesos',
        title: 'Procesos',
        translate: 'MENU.SAMPLE',
        type: 'item',
        icon: 'clock',
        url: 'historial-carga'
      },
      {
        id: 'historialTransacciones',
        title: 'Transacciones',
        translate: 'MENU.SAMPLE',
        type: 'item',
        icon: 'compass',
        url: 'historial-transacciones'
      }
    ]
  },
  {
    id: 'Proceso',
    title: 'CARGA DE ARCHIVOS',
    type: 'section',
    children: [
      {
        id: 'carga',
        title: 'Carga de Archivos',
        translate: 'MENU.HOME',
        type: 'item',
        icon: 'file-text',
        url: 'carga'
      },

    ]
  },
  {
    id: 'parametrizacion',
    title: 'Parametrización',
    type: 'section',
    children: [
      {
        id: 'notificaciones',
        title: 'Notificaciones',
        type: 'item',
        icon: 'file-text',
        url: 'notificaciones'
      }
    ]
  },
  {
    id: 'novedades',
    title: 'Novedades',
    type: 'section',
    children: [
      {
        id: 'novedades',
        title: 'Novedades',
        type: 'item',
        icon: 'file-text',
        url: 'novedades'
      }
    ]
  }
]