import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {

  constructor() { }

  alertNotification(tipo:SweetAlertIcon, mensaje:string, width?:number){
    Swal.fire({
      width: width,
      position: 'center',
      icon: tipo,
      title: mensaje,
      showConfirmButton: false,
      timer: 3000
    })
  }

  alertNotificationWithTime(tipo:SweetAlertIcon, mensaje:string, time:number){
    Swal.fire({
      position: 'center',
      icon: tipo,
      title: mensaje,
      showConfirmButton: false,
      timer: time
    })
  }
}
