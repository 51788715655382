<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- Historial List -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>
    <app-loading-modal #loadingModal></app-loading-modal>
    <section class="users-list-wrapper">
      <div class="card">
        <!-- List Header -->
        <div class="ml-1 mr-1 mt-1 mb-1">
          <div class="row mb-1">
            <div class="col-md-4 col-12">
              <h5>Filtro de búsqueda</h5>
            </div>
            <div class="col-md-8 col-12">
              <span *ngIf="archivoId">
                Archivo leido: &nbsp;&nbsp;{{archivoId}}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-7 col-12 d-flex align-items-left">
              <input name="datepicker" class="hidden form-control" ngbDatepicker #rangeSelectionDP="ngbDatepicker"
                [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                outsideDays="hidden" [startDate]="fromDate!" [value]="formatter.format(today)" tabindex="-1">

              <ng-template #t let-date let-focused="focused">
                <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                  [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                  (mouseleave)="hoveredDate = null">
                  {{ date.day }}
                </span>
              </ng-template>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-4 mb-1">
                  <div class="input-group">
                    <input #dpFromDate placeholder="Fecha Inicio" name="dpFromDate" class="form-control"
                      [value]="formatter.format(fromDate)"
                      (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary feather icon-calendar"
                        (click)="rangeSelectionDP.toggle()" type="button" rippleEffect></button>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4">
                  <div class="input-group">
                    <input #dpToDate class="form-control" placeholder="Fecha Final" name="dpToDate"
                      [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary feather icon-calendar"
                        (click)="rangeSelectionDP.toggle()" type="button" rippleEffect></button>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-3 mb-0 mb-sm-0 mt-1 mt-md-0">
                  <div class="input-group" *ngIf="!disableBtnFecha">
                    <button class="btn bg-light-warning mb-1" (click)="filterDate()" rippleEffect>
                      <strong>Aplicar</strong>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-12">
              <div class="d-flex justify-content-md-end align-items-end">
                <div class="w-50">
                  <fieldset class="form-group mb-0">
                    <ng-select [items]="selectStatus" [(ngModel)]="selectedStatus" bindLabel="name"
                      placeholder="Seleccione estado " (change)="filterAll()"></ng-select>
                  </fieldset>
                </div>
                <div *ngIf="enableClearFilter">
                  <button type="button" (click)="clearAllFilters()" class="btn btn-primary ml-1" rippleEffect>X
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--/List Header -->

      <div class="card">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="d-flex justify-content-between align-items-center m-1">
              <label class="d-flex align-items-center">Ver&nbsp;
                <select class="form-control mx-25" [(ngModel)]="selectedOption"
                  (ngModelChange)="changePageSize($event)">
                  <option *ngFor="let opt of limitOptions" [ngValue]="opt.value">{{opt.key}}</option>
                </select>
                entradas
              </label>
              <div class="col-sm-7">
                <button class="btn btn-primary mr-2" (click)="linkTransacciones()">Volver</button>

                <!-- Botón Descargar Archivo solo si archivoId existe -->
                <div ngbDropdown class="d-inline-block" *ngIf="archivoId">
                  <button class="btn btn-primary" id="dropdownBasic1" ngbDropdownToggle>
                    Descargar Archivo
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button class="dropdown-item" (click)="descargarArchivo('CSV')">Descargar Archivo</button>
                    <button class="dropdown-item" (click)="crearNovedad()">Descargar CSV</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-12 d-flex justify-content-start justify-content-md-end">
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0">
              <div *ngIf="isLoadingPage" class="spinner-border spinner-border mr-1" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <a *ngIf="!isLoadingPage" class="mr-1" container="body" placement="top"
                ngbTooltip="Actualizar transacciones" (click)="getTransactions()">
                <i size="25" data-feather="refresh-cw"></i>
              </a>
              <label class="d-flex align-items-center ml-1 ml-md-0">
                <input [(ngModel)]="searchValue" name="searchValue" type="search" class="form-control"
                  placeholder="Busqueda..." (keyup)="filterAll()" (input)="filterAll()" />
              </label>

              <button class="btn bg-light-warning ml-1 w-50" disabled="true" rippleEffect>Anular</button>

            </div>
          </div>

        </div>

        <!-- Historial List Table -->
        <ngx-datatable #table [rows]="rows" [rowHeight]="30" class="bootstrap core-bootstrap" [limit]="selectedOption"
          [columnMode]="ColumnMode.force" [headerHeight]="120" [footerHeight]="50" [scrollbarH]="true"
          [messages]="{emptyMessage: ''}">
          <ngx-datatable-column name="Acción" [width]="40" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div class="d-flex align-items-center col-actions">
                <a class="mr-1" routerLink="/historial-transacciones/{{row.archivoId}}/{{row.idTransaccion}}/detalle"
                  container="body" placement="top" ngbTooltip="Detalle Transacción"><i size="18"
                    data-feather="eye"></i></a>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Archivo" prop="archivoId" [width]="100">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.archivoId }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="estado" prop="estado" [width]="50"> 
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="badge badge-glow" [ngClass]="{
                'badge-warning': row.estado == 'En Proceso',
                'badge-success': row.estado == 'Aprobado',
                'badge-danger': row.estado == 'Rechazado'
              }">
                {{row.estado}}
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Fecha" prop="fechaCargue" [width]="80">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.fechaTransaccion}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Número transacción" prop="idTransaccion" [width]="150">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.idTransaccion}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Tarjeta" prop="ultimosTarjeta" [width]="50">
            <ng-template let-row="row" ngx-datatable-cell-template>
              ****{{row.ultimosTarjeta}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Respuesta" prop="codRespuesta" [width]="50">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.codRespuesta}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Mensaje Respuesta" prop="descRespuesta" [width]="350">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.descRespuesta}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Valor" prop="valor" [width]="50">
            <ng-template let-row="row" ngx-datatable-cell-template>
              ${{row.valor}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
              let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">

              <div style="padding: 5px 10px" *ngIf="rowCount > 0 && !allRowsRead">
                Leyendo los primeros {{rowCount}} registros
              </div>

              <div style="padding: 5px 10px" *ngIf="rowCount > 0 && allRowsRead">
                Total de registros {{rowCount}}
              </div>

              <div style="padding: 40%" *ngIf="rowCount == 0 && !isLoadingPage">
                No hay registros ...
              </div>

              <div style="padding: 40%" *ngIf="rowCount == 0  && isLoadingPage">
                <img src="assets/images/icons/loading.gif" />
              </div>

              <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                [pagerNextIcon]="'datatable-icon-skip'" [count]="rowCount" [page]="curPage" [size]="pageSize"
                (change)="onChange($event)" [hidden]="!((rowCount / pageSize) > 1)">
              </datatable-pager>
            </ng-template>

          </ngx-datatable-footer>

        </ngx-datatable>
        <!--/ Historial List Table -->
      </div>
    </section>
    <!--/ Historial List -->
  </div>
</div>