<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- Historial List -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>
    <app-loading-modal #loadingModal></app-loading-modal>
    <section class="users-list-wrapper">
      <div class="card">
        <!-- Historial List Header -->
        <div class="ml-1 mr-1 mt-1 mb-1">
          <div class="row">
            <div class="col-12 mb-1">
              <h5>Filtro de búsqueda</h5>
            </div>
            <div class="col-md-7 col-12 d-flex align-items-left">
              <input name="datepicker" class="hidden form-control" ngbDatepicker #rangeSelectionDP="ngbDatepicker"
                [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                outsideDays="hidden" [startDate]="fromDate!" [value]="formatter.format(today)" tabindex="-1">

              <ng-template #t let-date let-focused="focused">
                <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                  [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                  (mouseleave)="hoveredDate = null">
                  {{ date.day }}
                </span>
              </ng-template>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-4 mb-1">
                  <div class="input-group">
                    <input #dpFromDate placeholder="Fecha Inicio" name="dpFromDate" class="form-control"
                      [value]="formatter.format(fromDate)"
                      (input)="fromDate = validateInput(fromDate, dpFromDate.value)">

                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary feather icon-calendar"
                        (click)="rangeSelectionDP.toggle()" type="button" rippleEffect></button>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4">
                  <div class="input-group">
                    <input #dpToDate class="form-control" placeholder="Fecha Final" name="dpToDate"
                      [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary feather icon-calendar"
                        (click)="rangeSelectionDP.toggle()" type="button" rippleEffect></button>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-3 mb-0 mb-sm-0 mt-1 mt-md-0">
                  <div class="input-group" *ngIf="!disableBtnFecha">
                    <button class="btn bg-light-warning mb-1" (click)="filterDate()" rippleEffect>
                      <strong>Aplicar</strong>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-sm-12">
              <div class="d-flex justify-content-md-end align-items-end">
                <div class="w-50">
                  <fieldset class="form-group mb-0">
                    <ng-select [items]="selectStatus" [(ngModel)]="selectedStatus" bindLabel="name"
                      placeholder="Seleccione estado " (change)="filterAll()"></ng-select>
                  </fieldset>
                </div>
                <div *ngIf="enableClearFilter">
                  <button type="button" (click)="clearAllFilters()" class="btn btn-primary ml-1" rippleEffect>X
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--/ Historial List Header -->

      <!-- Historial List Table -->
      <div class="card">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="d-flex justify-content-between align-items-center m-1">
              <label class="d-flex align-items-center">Ver&nbsp;
                <select class="form-control mx-25" [(ngModel)]="selectedOption"
                  (ngModelChange)="changePageSize($event)">
                  <option *ngFor="let opt of limitOptions" [ngValue]="opt.value">{{opt.key}}</option>
                </select>
                entradas
              </label>
            </div>
          </div>
          <div class="col-md-6 col-12 d-flex justify-content-start justify-content-md-end">
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0">
              <div *ngIf="isLoadingPage" class="spinner-border spinner-border mr-1" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <a *ngIf="!isLoadingPage" class="mr-1" container="body" placement="top" ngbTooltip="Actualizar procesos"
                (click)="getProcesses()">
                <i size="25" data-feather="refresh-cw"></i>
              </a>
              <label class="d-flex align-items-center ml-1 ml-md-0">
                <input [(ngModel)]="searchValue" name="searchValue" type="search" class="form-control"
                  placeholder="Busqueda..." (keyup)="filterAll()" (input)="filterAll()" />
              </label>
              <button class="btn btn-primary ml-1" routerLink="/carga" rippleEffect>
                <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i><span
                  class="d-none d-sm-inline-block">Agregar</span></button>
            </div>
          </div>
        </div>
        <ngx-datatable #table [rows]="rows" [rowHeight]="30" class="bootstrap core-bootstrap" [limit]="selectedOption"
          [columnMode]="ColumnMode.force" [headerHeight]="120" [footerHeight]="50" [scrollbarH]="true"
          [messages]="{emptyMessage: ''}">
          <ngx-datatable-column name="Acción" [width]="120" [sortable]="false" [minWidth]="120">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div class="d-flex align-items-center col-actions">
                <a class="mr-1" container="body" placement="top" ngbTooltip="Ver Transacciones"
                  routerLink="/historial-transacciones/{{row.archivoId}}"
                  *ngIf="row.estado !== 'Fallido'"><i size="18" data-feather="send"></i></a>
                <a class="mr-1" routerLink="/historial-carga/{{row.comercioId}}/{{row.archivoId}}/detalle"
                  container="body" placement="top" ngbTooltip="Detalle Archivo"><i size="18" data-feather="eye"></i></a>

                <!-- <a *ngIf="row.estado == 'Aprobado'" class="mr-1" container="body" placement="top"
                  ngbTooltip="Generar archivo" (click)="generarArchivo(row.comercioId, row.archivoId)"><i size="18"
                    data-feather="file"></i></a> -->

                <!-- Menú desplegable usando ngbDropdown para mejor compatibilidad con Angular -->
                <div ngbDropdown *ngIf="row.estado == 'APROBADO'" class="dropdown">
                  <button ngbDropdownToggle class="btn btn-link p-0 text-primary">
                    <i size="18" class="text-primary mr-1" data-feather="download"></i>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                    <button class="dropdown-item" (click)="getUrlFile(row.comercioId, row.archivoId, row.archivoOriginal, 'CSV')">
                      Descargar Archivo
                    </button>
                    <button class="dropdown-item" (click)="crearNovedad(row.comercioId, row.archivoId)">
                      Descargar CSV
                    </button>                    
                  </div>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Archivo" prop="archivoOriginal" [width]="300" [minWidth]="300">
            <ng-template let-row="row" ngx-datatable-cell-template ngbTooltip="Ver Transacciones">
              <ion-icon name="checkmark-outline" *ngIf="row?.nombreConvenio==null"></ion-icon>
              {{row?.archivoOriginal}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="estado" prop="estadoView" [width]="120" [minWidth]="120">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="badge badge-glow" [ngClass]="{
                  'badge-secondary': row.estado == 'PENDIENTE' || row.estado == 'SUSPENDIDO',
                  'badge-warning': row.estado == 'PROCESANDO' || row.estado == 'VERIFICANDO' || row.estado == 'VERIFICADO' || row.estado == 'PROCESANDO RESPUESTAS' || row.estado == 'VALIDANDO RESPUESTAS' || row.estado == 'FINALIZADAS RESPUESTAS' || row.estado == 'GENERANDO RESPUESTAS' || row.estado == 'GENERANDO ARCHIVO',
                  'badge-success': row.estado == 'APROBADO',
                  'badge-danger': row.estado == 'RECHAZADO' 
                }">
                {{row?.estadoView}}
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Operación" prop="archivoId" [width]="230" [minWidth]="230">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div [ngClass]="{'badge badge-glow badge-success': row.archivoId == newProcess}">
                {{ row?.archivoId }}
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Convenio" prop="nombreConvenio" [width]="300" [minWidth]="300">
            <ng-template let-row="row" ngx-datatable-cell-template ngbTooltip="Ver Transacciones">
              <ion-icon name="checkmark-outline" *ngIf="row?.nombreConvenio==null"></ion-icon>
              {{row?.nombreConvenio}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Pagos" prop="totalTx" [width]="80" [minWidth]="80">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row?.totalTx}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Fecha Hora" prop="fechaHoraProceso" [width]="200" [minWidth]="200">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row?.fechaHoraProceso}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Respuesta" prop="mensajeRespuesta" [width]="300" [minWidth]="300">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row?.mensajeRespuesta}}
            </ng-template>
          </ngx-datatable-column>
        
          <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
              let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">

              <div style="padding: 5px 10px" *ngIf="rowCount > 0 && !allRowsRead">
                Leyendo los primeros {{rowCount}} registros
              </div>

              <div style="padding: 5px 10px" *ngIf="rowCount > 0 && allRowsRead">
                Total de registros {{rowCount}}
              </div>

              <div style="padding: 40%" *ngIf="rowCount == 0 && !isLoadingPage && !firstLoad">
                No hay registros ...
              </div>

              <div style="padding: 40%" *ngIf="rowCount == 0 && !isLoadingPage && firstLoad">
                Seleccione los filtros deseados para visualizar los procesos.
              </div>

              <div style="padding: 45%" *ngIf="rowCount == 0  && isLoadingPage">
                <img src="assets/images/icons/loading.gif" />
              </div>

              <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                [pagerNextIcon]="'datatable-icon-skip'" [count]="rowCount" [page]="curPage" [size]="pageSize"
                (change)="onChange($event)" [hidden]="!((rowCount / pageSize) > 1)">
              </datatable-pager>
            </ng-template>

          </ngx-datatable-footer>
        </ngx-datatable>
        <!--/ Invoice List Table -->
      </div>
    </section>
    <!--/ Invoice List -->
  </div>
</div>