import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DobleFactorDto } from 'app/main/models/DobleFactorDto';
import { User } from 'app/main/models/user';
import { environment } from 'environments/environment';
import { from, throwError,BehaviorSubject, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {


  public currentUser: Observable<User>;
  public loggedIn: BehaviorSubject<boolean>;
  private currentUserSubject: BehaviorSubject<User>;


  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('payload')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.loggedIn = new BehaviorSubject<boolean>(false);
  }

  public administrationService: string = `${environment.apiAdminService}`;

  // Método para verificar la expiración de la contraseña
  checkPasswordExpiration(username: string): Observable<any> {
    return this.http.get(`${this.administrationService}/check-password-expiration?username=${username}`)
      .pipe(
        catchError(error => {
          return throwError(error);  // Propaga el error para ser manejado en el componente
        })
      );

  }

  auth(username: string, password: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*'
    });
    const body = new HttpParams()
      .set('username', username)
      .set('password', password);
    const httpOptions = {
      withCredentials: true,
      headers: headers
    };

    return this.http.post(`${this.administrationService}/autenticacion/auth`, body.toString(), httpOptions).pipe(
      tap(response => {
        if (response) {
        }
      }),
      catchError(this.handleError)
    )
  }

  generateQR() {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    });

    const httpOptions = {
      withCredentials: true,
      headers: headers
    };

    return this.http.get<{ data: string }>(`${this.administrationService}/autenticacion/generate`, httpOptions).pipe(
      tap(response => {
      }),
      catchError(this.handleError)
    );
  }

  validateCode(request: DobleFactorDto) {

    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
     });

    const httpOptions = {
      withCredentials: true,
      headers: headers
    };

    return this.http.post(`${this.administrationService}/autenticacion/validate`, request, httpOptions).pipe(
      tap(response => {
        if (response) {
        }
      }),
      catchError(this.handleError)
    )
  }


  forgotPassword(username:string ) {
    let params = new HttpParams();
    params = params.append('username', username);

    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
     });

    const httpOptions = {
      withCredentials: true,
      headers: headers,
      params: params
    };

    return this.http.post(`${this.administrationService}/forgot-password-v1`,null, httpOptions).pipe(
      tap(response => {
        if (response) {
        }
      }),
      catchError(this.handleError)
    )
  }

  forgotPasswordForce(username:string ) {
    let params = new HttpParams();
    params = params.append('username', username);

    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
     });

    const httpOptions = {
      withCredentials: true,
      headers: headers,
      params: params
    };

    return this.http.post(`${this.administrationService}/forgot-password-v1`,null, httpOptions).pipe(
      tap(response => {
        if (response) {
        }
      }),
      catchError(this.handleError)
    )
  }

  forgotPasswordSubmit(username:string, code:string, newPassword:string, newPasswordConfirmation:string ) {
    let params = new HttpParams();
    params = params.append('username', username);

    const body = new HttpParams()
      .set('code', code)
      .set('newPassword', newPassword)
      .set('newPasswordConfirmation', newPasswordConfirmation);
      
    const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*'
      });  

    const httpOptions = {
      withCredentials: true,
      headers: headers
    };

    return this.http.post(`${this.administrationService}/reset-password-v1/`+username, body.toString(),httpOptions).pipe(
      tap(response => {
        if (response) {
        }
      }),
      catchError(this.handleError)
    )
  }



  savePayload(atributos) {
    localStorage.setItem('payload', JSON.stringify(atributos));
  }

  login(user: User) {

    this.currentUserSubject.next(user);
    this.savePayload(user)
    return user;  
  }

  getPayload() {
    return JSON.parse(localStorage.getItem('payload'));
  }

    public get userValue() {
        return this.currentUserSubject.value;
    }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ocurrió un error:', error.error.message);
    }
    return throwError(error);
  }
}
