import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { throwError , Observable} from 'rxjs';
import { BinesSaveOrUpdateDto } from 'app/main/models/BinesSaveOrUpdateDto';
import { ListParametroDto } from 'app/main/models/ListParametroDto';
import { GeneralResponse } from 'app/main/models/GeneralResponse';
import { LocalStorageService } from 'app/main/service/local-storage.service';
import { HttpHeaders } from '@angular/common/http';


@Injectable({ providedIn: 'root' })
export class BinesService  {

  public endPointPostGress:string = `${environment.apiAdminService}`;
  public comercio_id: number;
  public administrationService: string = `${environment.apiAdminService}`;
  

  /**
   * Constructor
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient,
    private localStorageService:LocalStorageService) {
  }

  updateParametrosNotificaciones(request: { correoAlertas: string; correoNotificaciones: string; notificacionesDto: string; }) {
    this.comercio_id = this.localStorageService.getItem('comercioSelected');
    const endpoint = `${this.administrationService}/parametro/notificaciones/comercios/${this.comercio_id}`;

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    // Convertimos el objeto request en un string URL-encoded
    const body = new URLSearchParams();
    body.set('correoAlertas', request.correoAlertas);
    body.set('correoNotificaciones', request.correoNotificaciones);
    body.set('notificacionesDto', request.notificacionesDto);

    // Cambia `put` por `post`
    return this.http.post<GeneralResponse>(endpoint, body.toString(), { headers });
}



  /**
   * 
   */
    getParametrosNotificacionesByComercio() {

      this.comercio_id=this.localStorageService.getItem('comercioSelected')

      const endpoint = `${this.administrationService}/parametro/notificaciones/comercios/${this.comercio_id}`;

      return this.http.get<GeneralResponse>(endpoint);
    }

    /**
   * Servicio encargado de traer los parametros de las notificaciones
   * @returns 
   */
  getParametrosNotificaciones() {
    return this.http.get(`${this.endPointPostGress}/parametro/findAllNotifications`).pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )
  }

  // Método para obtener el tiempo de inactividad
  obtenerParametros(): Observable<any> { 
    return this.http.get(`${this.endPointPostGress}/parametro/cierreSesion/tiempoInactividad`);
  }

  // Método para obtener el tiempo de cierre de sesión
  obtenerTiempoCierresesion(): Observable<any> { 
    return this.http.get(`${this.endPointPostGress}/parametro/cierreSesion/tiempoCierreSesion`);
  }


  /**
  * Catch errors on call WS
  * @param error
  */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ocurrió un error:', error.error.message);
    }
    return throwError(error);
  }

  
}
