<div class="loading-modal" *ngIf="isVisible">
  <div class="modal-content">
    <!-- Botón de cierre "X" en la esquina superior derecha -->
    <button class="close-button" (click)="closeModal()">×</button>

    <!-- Título del modal -->
    <h3 class="modal-title">{{ message }}</h3>

    <!-- Barra de progreso -->
    <div class="progress-bar-container">
      <div class="progress-bar" [style.width.%]="progress"></div>
    </div>

    <!-- Texto debajo de la barra de progreso -->
    <p class="progress-text" *ngIf="progress < 100">Por favor, espere mientras procesamos el archivo...</p>
    <p class="progress-text" *ngIf="progress === 100">¡Archivo listo! Puede ir a novedades para descargarlo.</p>

    <!-- Botón "Ir a Novedades" solo aparece cuando el progreso está completo -->
    <button class="navigate-button" *ngIf="progress === 100" (click)="irNovedades()">Ir a Novedades</button>
  </div>
</div>
