import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, EMPTY  } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from 'app/auth/service';
import { SweetAlertService } from 'app/main/services/sweet-alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert2';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private alert: SweetAlertService,
    private modalService: NgbModal
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Si el método es DELETE, pasar la solicitud sin modificar
    if (request.method.includes("DELETE")) {
      return next.handle(request);
    }

    const isAuthRoute = request.url.includes('/autenticacion/auth') 
    || request.url.includes('/pages/authentication/login-v1');    
    
    if(isAuthRoute){
      return next.handle(request);
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error("Error interceptado por ErrorInterceptor:", error); // Depuración

        if (error.status === 0 && !request.url.includes('/administration-service/api/v1/auditoria')){
          console.error("url",request.url,"error: ",error.status)
            // Redirigir al login
          this._router.navigate(['/pages/authentication/login-v1']);
          // Cerrar cualquier modal o alerta existente
          swal.fire({
                icon: 'warning', // Tipo de alerta (error, warning, info, etc.)
                title: 'No se pudo conectar al servidor',
                text: 'No podemos comunicarnos con el servidor en este momento. Intenta nuevamente más tarde.', // Mensaje personalizado adicional
                confirmButtonText: 'Entendido', // Botón de confirmación
              });

        } else if (error.status === 403) {
            console.log("Manejando sesión caducada o error de conexión"); // Depuración

            // Cerrar cualquier modal o alerta existente
            this.modalService.dismissAll();
            this.alert.closeAllAlerts();
            
            console.log("Alertas y modales cerrados, mostrando la alerta de sesión caducada"); // Depuración

            // Mostrar la alerta de sesión caducada
            swal.fire({
              icon: 'error', // Tipo de alerta (error, warning, info, etc.)
              title: 'La sesión ha caducado',
              text: 'Vuelve a iniciar sesión', // Mensaje personalizado adicional
              confirmButtonText: 'Aceptar', // Botón de confirmación
              allowOutsideClick: true // Evita que se cierre la alerta al hacer clic fuera de ella
            });

            // Redirigir al login o realizar cualquier otra acción después
            this._router.navigate(['/pages/authentication/login-v1']);

          } else if (error.status === 429){

            // Cerrar cualquier modal o alerta existente
            this.modalService.dismissAll();
            this.alert.closeAllAlerts();
            swal.fire({
              icon: 'warning', // Tipo de alerta (error, warning, info, etc.)
              title: 'Demasiadas solicitudes',
              text: 'Has enviado demasiadas solicitudes en un corto período de tiempo. Por favor, espera 5 minutos antes de intentarlo nuevamente.', // Mensaje personalizado adicional
              confirmButtonText: 'Entendido', // Botón de confirmación
              allowOutsideClick: true // Evita que se cierre la alerta al hacer clic fuera de ella
            });
          } else if (error.status === 401) {
            console.log("Manejando sesión caducada o error de conexión"); // Depuración

            // Cerrar cualquier modal o alerta existente
            this.modalService.dismissAll();
            this.alert.closeAllAlerts();
            
            console.log("Alertas y modales cerrados, mostrando la alerta de sesión caducada"); // Depuración

            // Redirigir al login o realizar cualquier otra acción después
            this._router.navigate(['/pages/authentication/login-v1']);

          }

        // Continuar con otros errores que no sean críticos
        return throwError(error);
      })
    );
  }
}
