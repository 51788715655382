import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BinesService } from '../parametrización/parametrizacion.service';
import { catchError, tap } from 'rxjs/operators';
import { throwError, Observable} from 'rxjs';
import { NovedadesComponent } from './novedades.component';

@Injectable({ providedIn: 'root' })
export class NovedadesService  {

  public endPointPostGress:string = `${environment.apiAdminService}`;

  /**
   * Constructor
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient) {}

  getNovedades(): Observable<any> {
    return this.http.get<any>(`${this.endPointPostGress}/usuarios/novedades`);
  }
  
}
