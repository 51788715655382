import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss']
})
export class LoadingModalComponent {
  @Input() isVisible: boolean = false;
  message: string = 'Generando Archivo';
  progress: number = 0;

  constructor(private router: Router) {}

  closeModal(): void {
    this.isVisible = false;
  }

  irNovedades(): void {
    this.isVisible = false;
    this.router.navigate(['/novedades']);
  }

  updateProgress(newMessage: string, progressValue: number): void {
    this.message = newMessage;
    this.progress = progressValue;
  }
}
