import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BinesService } from '../parametrizacion.service';
import { SweetAlertService } from 'app/main/services/sweet-alert.service';
import { GeneralResponse } from 'app/main/models/GeneralResponse';
import { Error } from 'app/main/models/Error';
import { ListParametroDto } from 'app/main/models/ListParametroDto';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.scss']
})
export class listarNotificaciones implements OnInit {
  public generalResponse: GeneralResponse;
  public generalError: Error;
  public contentHeader: object;
  public formNotificaciones: FormGroup;
  public guardandoNotificaciones = false;
  public resultString: string = '';
  public params: ListParametroDto;


  constructor(private formBuilder: FormBuilder,
    private binesService: BinesService,
    private alert: SweetAlertService) { }

  ngOnInit(): void {
    this.initContentHeader();
    this.getAllParametros();

    this.formNotificaciones = this.formBuilder.group({
      pendiente: false,
      verificacion: false,
      proceso: false,
      generacion: false,
      aprobado: false,
      rechazado: false,
      suspendido: false,
      correoNotificaciones: [''],
      correoAlerta: ['', Validators.required],
    });

    const checkboxes = ['pendiente', 'verificacion', 'proceso', 'generacion', 'aprobado', 'rechazado', 'suspendido'];
    checkboxes.forEach(checkbox => {
      this.formNotificaciones.get(checkbox).valueChanges.subscribe(() => {
        this.updateCorreoNotificacionesRequired();
      });
    });
  }

  initContentHeader() {
    this.contentHeader = {
      headerTitle: 'Notificaciones',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
        ]
      }
    };
  }

  getAllParametros() {

    this.binesService.getParametrosNotificacionesByComercio().subscribe(data => {
    
      this.generalResponse = data as GeneralResponse;
        
        if (this.generalResponse && this.generalResponse.data) {
            // Directamente asignar el array 'data' a 'parametroDtoList'
            this.params = { parametroDtoList: this.generalResponse.data };

            if (Array.isArray(this.params.parametroDtoList)) {
                this.setValues();
            } else {
                console.error('parametroDtoList no es un array o es undefined', this.params);
            }
        }
    }, err => {
    });
}

setValues() {
  if (this.params && Array.isArray(this.params.parametroDtoList)) {
      const findParametroByTipo = (tipo: string) => this.params.parametroDtoList.find(parametro => parametro.tipoParametro === tipo)?.valorParametro || '';

      this.formNotificaciones.setValue({
          pendiente: findParametroByTipo('Estados').includes('PENDIENTE'),
          verificacion: findParametroByTipo('Estados').includes('VERIFICANDO'),
          proceso: findParametroByTipo('Estados').includes('PROCESANDO'),
          generacion: findParametroByTipo('Estados').includes('GENERANDO_RESPUESTAS'),
          aprobado: findParametroByTipo('Estados').includes('APROBADO'),
          rechazado: findParametroByTipo('Estados').includes('RECHAZADO'),
          suspendido: findParametroByTipo('Estados').includes('SUSPENDIDO'),
          correoNotificaciones: findParametroByTipo('Correo de notificaciones'),
          correoAlerta: findParametroByTipo('Correo de alertas'),
      });
  } else {
      console.error('parametroDtoList no es un array o es undefined', this.params);
  }
}






  updateCorreoNotificacionesRequired() {
    const anyCheckboxSelected = ['pendiente', 'verificacion', 'proceso', 'generacion', 'aprobado', 'rechazado', 'suspendido']
      .some(checkbox => this.formNotificaciones.get(checkbox).value);

    if (anyCheckboxSelected) {
      this.formNotificaciones.get('correoNotificaciones').setValidators([Validators.required]);
    } else {
      this.resultString = '';
      this.formNotificaciones.get('correoNotificaciones').clearValidators();
    }

    this.formNotificaciones.get('correoNotificaciones').updateValueAndValidity();
  }

  guardar() {
    this.guardandoNotificaciones = true;

    if (!this.formNotificaciones.valid) {
        this.guardandoNotificaciones = false;
        return this.alert.alertNotificationWithTime('error', 'Formulario inválido.', 4000);
    }

    const request = this.buildRequest();

    this.binesService.updateParametrosNotificaciones(request).subscribe(
        data => {
            this.generalResponse = data;

            if (this.generalResponse.status !== 200) {
                return this.alert.alertNotificationWithTime('error', 'Error al guardar los parámetros', 4000);
            }

            this.guardandoNotificaciones = false;
            this.alert.alertNotificationWithTime('success', '¡Los datos se han guardado exitosamente!', 2000);
        },
        err => {
            this.guardandoNotificaciones = false;
            this.generalError = err.error.data;
            this.alert.alertNotificationWithTime('error', err.error.data, 4000);
        }
    );
}

buildRequest(): any {
    this.updateResultString();

    const request = {
        correoAlertas: this.formNotificaciones.controls['correoAlerta'].value,
        correoNotificaciones: this.formNotificaciones.controls['correoNotificaciones'].value,
        notificacionesDto: this.resultString
    };

    return request;
}




  updateResultString() {
    const valueMappings = {
      pendiente: 'PENDIENTE',
      verificacion: 'VERIFICANDO',
      proceso: 'PROCESANDO',
      generacion: 'GENERANDO_RESPUESTAS',
      aprobado: 'APROBADO',
      rechazado: 'RECHAZADO',
      suspendido: 'SUSPENDIDO'
    };

    const selectedValues = Object.keys(this.formNotificaciones.value)
      .filter(key => this.formNotificaciones.value[key])
      .map(key => valueMappings[key])
      .join(', ');

    this.resultString = selectedValues;

    console.log("Estados seleccionados (resultString): ", this.resultString);
  }
}