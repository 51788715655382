<app-content-header [contentHeader]="contentHeader"></app-content-header>
<div class="card">
  <div class="row d-flex justify-content-center py-3" *ngIf="cargandoRecaudo">
    <img src="assets/images/icons/loading.gif"/>
  </div>
  <div class="modal-content p-0" *ngIf="!cargandoRecaudo">
    <div class="card-body">
      <form class="form form-horizontal" [formGroup]="formRecaudo">
        <div class="row">
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Comercio Id</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="comercioId" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Archivo Cargado</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="archivoOriginal" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Operacion</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="archivoId" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Descripción</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" class="form-control" [value]="getFormattedDescripcion(proceso.descripcion)" readonly />
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Banco</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="nombreBanco" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Convenio</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="convenioId" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Nombre convenio</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="nombreConvenio" class="form-control" disabled/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Mensaje de Respuesta</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="mensajeRespuesta" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Estado</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="estadoView" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Fecha Proceso</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="fechaProceso" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Secuencia</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="secuencia" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Total Transacciones</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="totalTx" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Total Valor Transacciones</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="totalValorTx" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Transacciones Aprobadas</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="totalTxAprobadas" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Valor Transacciones Aprobadas</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="totalValorTxAprobadas" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Transacciones Rechazadas</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="totalTxRechazadas" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Valor Transacciones Rechazadas</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="totalValorTxRechazadas" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Formato de archivo</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="tipoFormato" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
                <div class="col-md-2"></div>
                <div class="col-sm-12 col-12 col-md-10">
                  <button class="btn btn-primary" (click)="linkProcesos()">Volver</button>
                </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>