<app-content-header [contentHeader]="contentHeader"></app-content-header>
<div class="card">
  <!-- Convenio List Header -->
  <div class="modal-content p-0">
    <div class="card-body">
      <form class="form form-horizontal mt-2" [formGroup]="formNotificaciones">
        <div class="form-group">
          <div class="row">
            <div class="col-12">
              <div class="form-group row">
                <div class="col-md-12 col-form-label">
                  <label for="first-name"><strong>Estados a Notificar</strong></label>
                </div>
                <div class="col-md-2">
                  <div class="form-check form-switch">
                    <div class="custom-control custom-control-primary custom-switch">
                      <input type="checkbox" class="custom-control-input" id="verificacion"
                        formControlName="verificacion" (change)="updateResultString()" />
                      <label class="custom-control-label" for="verificacion">Verificación</label>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-check form-switch">
                    <div class="custom-control custom-control-primary custom-switch">
                      <input type="checkbox" class="custom-control-input" id="proceso" formControlName="proceso"
                        (change)="updateResultString()" />
                      <label class="custom-control-label" for="proceso">En proceso</label>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-check form-switch">
                    <div class="custom-control custom-control-primary custom-switch">
                      <input type="checkbox" class="custom-control-input" id="generacion" formControlName="generacion"
                        (change)="updateResultString()" />
                      <label class="custom-control-label" for="generacion">Generación</label>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-check form-switch">
                    <div class="custom-control custom-control-primary custom-switch">
                      <input type="checkbox" class="custom-control-input" id="aprobado" formControlName="aprobado"
                        (change)="updateResultString()" />
                      <label class="custom-control-label" for="aprobado">Aprobado</label>
                    </div>
                  </div>
                </div>  
                <div class="col-md-2">
                  <div class="form-check form-switch">
                    <div class="custom-control custom-control-primary custom-switch">
                      <input type="checkbox" class="custom-control-input" id="rechazado" formControlName="rechazado"
                        (change)="updateResultString()" />
                      <label class="custom-control-label" for="rechazado">Rechazado</label>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-check form-switch">
                    <div class="custom-control custom-control-primary custom-switch">
                      <input type="checkbox" class="custom-control-input" id="suspendido" formControlName="suspendido"
                        (change)="updateResultString()" />
                      <label class="custom-control-label" for="suspendido">Suspendido</label>
                    </div>
                  </div>
                </div>  
              </div>
            </div>
            <div class="col-12">
              <div class="form-group row">
                <div class="col-sm-2 col-form-label">
                  <label for="first-name"><strong>Correos de notificaciones <strong *ngIf="resultString.length > 0" style="color: red;">*</strong></strong></label>
                </div>
                <div class="col-sm-12">
                  <textarea class="form-control altoTextarea" formControlName="correoNotificaciones"
                    placeholder="Ingresar correos separados por comas" [ngClass]="{'is-invalid': resultString.length > 0 && formNotificaciones.get('correoNotificaciones').value == ''}"></textarea>

                    <div
                      *ngIf="resultString.length > 0 && formNotificaciones.get('correoNotificaciones').value == ''"
                      class="invalid-feedback">
                      El correo de notificación es requerido.
                    </div>
  
                    <!-- <div
                      *ngIf="formNotificaciones.get('correoNotificaciones').hasError('pattern') && (formNotificaciones.get('correoNotificaciones').dirty || formNotificaciones.get('correoNotificaciones').touched)"
                      class="invalid-feedback">
                      Correo invalido.
                    </div> -->
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group row">
                <div class="col-sm-2 col-form-label">
                  <label for="first-name"><strong>Correos de Alerta <strong
                        style="color: red;">*</strong></strong></label>
                </div>
                <div class="col-sm-12">
                  <textarea class="form-control altoTextarea" formControlName="correoAlerta"
                    placeholder="Ingresar correos separados por comas"
                    [ngClass]="{'is-invalid': ((formNotificaciones.get('correoAlerta').touched || formNotificaciones.get('correoAlerta').dirty) && formNotificaciones.get('correoAlerta').errors)}"></textarea>

                  <div
                    *ngIf="formNotificaciones.get('correoAlerta').hasError('required') && (formNotificaciones.get('correoAlerta').dirty || formNotificaciones.get('correoAlerta').touched)"
                    class="invalid-feedback">
                    El correo de alerta es requerido.
                  </div>

                  <!-- <div
                    *ngIf="formNotificaciones.get('correoAlerta').hasError('pattern') && (formNotificaciones.get('correoAlerta').dirty || formNotificaciones.get('correoAlerta').touched)"
                    class="invalid-feedback">
                    Correo invalido.
                  </div> -->
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="form-group row">
                <div class="col-sm-12 col-12 col-md-10">
                  <button rippleEffect class="btn btn-primary mr-1" (click)="guardar()" *ngIf="!guardandoNotificaciones"
                    [disabled]="!formNotificaciones.valid">
                    Guardar
                  </button>
                  <button class="btn btn-primary mr-1" type="button" disabled rippleEffect
                    *ngIf="guardandoNotificaciones">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span class="ml-25 align-middle">Guardando</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>