import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class HistorialTransaccionesService {
  public transactionEndPoint = `${environment.apiTransactionService}/transactions`;

  /**
   * Constructor
   *
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient) {
  }
  /**
   * Get rows
   */

  getTrxDataTable(archivoId, comercio_id, lastHashKey, lastRangeKey, lastIndexRangeKey, cantidadRegistros, fechaInicio, fechaFin, bin, numberControl, convenio, latestCard) {
    let generateUrl, route = 'all';
    if (comercio_id) {
      route = 'withFilters';
      generateUrl = `comercioId=${comercio_id}&maxResultSize=${cantidadRegistros}`;
    } else {
      generateUrl = `maxResultSize=${cantidadRegistros}`;
    }
    if (lastHashKey) {
      generateUrl += `&startKeyArchivoId=${lastHashKey}`;
    }

    if (lastRangeKey) {
      generateUrl += `&startKeyIdTransaccion=${lastRangeKey}`;
    }
    if (comercio_id) {
      if (lastIndexRangeKey) {
        generateUrl += `&startKeyFechaProceso=${lastIndexRangeKey}`;
      }

      if (fechaInicio) {
        let fechaInicial, fechaFinal;
        fechaInicial = fechaInicio.year + "-" + this.formatDate(fechaInicio.month) + "-" + this.formatDate(fechaInicio.day);
        if (fechaFin) {
          fechaFinal = fechaFin.year + "-" + this.formatDate(fechaFin.month) + "-" + this.formatDate(fechaFin.day);
        } else {
          fechaFinal = fechaInicial;
        }
        generateUrl += `&fromDate=${fechaInicial}&toDate=${fechaFinal}`;
      }

      if (bin) {
        generateUrl += `&bin=${bin}`;
      }

      if (latestCard) {
        generateUrl += `&latestCard=${latestCard}`;
      }

      if (numberControl) {
        generateUrl += `&numberControl=${numberControl}`;
      }

      if (convenio) {
        generateUrl += `&convenio=${convenio}`;
      }

      if (archivoId) {
        generateUrl += `&archivoId=${archivoId}`;
      }
    }
    return this.http.get(`${this.transactionEndPoint}/${route}?${generateUrl}`);
  }

  getTransaccion(archivoId: string, idTransaccion: string) {
    let params = new HttpParams();
    params = params.append('archivoId', archivoId);
    params = params.append('idTransaccion', idTransaccion);
    return this.http.get(`${this.transactionEndPoint}/getTransactionById`, { params: params });
  }

  formatDate(num: number): string {
    if (num < 10)
      return '0' + num;
    return num + '';
  }
}
