<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- Encabezado de Novedades -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <section class="invoice-list-wrapper">
      <div class="card">
        <!-- Filtros y Opciones -->
        <div class="row m-1">
          <div class="col-md-4 col-12">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <label class="d-flex align-items-center">Ver&nbsp;
                  <select class="form-control mx-20" [(ngModel)]="selectedOption"
                    (ngModelChange)="changePageSize($event)">
                    <option *ngFor="let opt of limitOptions" [ngValue]="opt.value">{{ opt.key }}</option>
                  </select>
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-12"></div>
        </div>

        <!-- Tabla de Novedades -->
        <ngx-datatable 
          #table 
          [rows]="rows" 
          [rowHeight]="30" 
          class="bootstrap core-bootstrap custom-table" 
          [limit]="selectedOption"
          [columnMode]="'force'" 
          [headerHeight]="50" 
          [footerHeight]="50" 
          [scrollbarH]="true"
          [messages]="{emptyMessage: 'No hay registros disponibles'}">

          <!-- Columna de Acciones -->
          <ngx-datatable-column name="Acción" [sortable]="false" [width]="150">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div class="d-flex align-items-center col-actions">
                <a class="mr-1" title="Descargar" (click)="descargarArchivo(row.archivoId, row.comercioId)"><i data-feather="download"></i></a>
                <a class="mr-1" title="Eliminar" (click)="eliminarNovedad(row.archivoId); $event.stopPropagation();">
                  <i data-feather="trash-2"></i>
                </a>  
              </div>
            </ng-template>
          </ngx-datatable-column>


          <!-- Columna de Acciones -->
          <ngx-datatable-column name="Archivo Id" [sortable]="false" [width]="150">
            <ng-template ngx-datatable-cell-template let-row="row">
              {{ row.archivoId }}
            </ng-template>
          </ngx-datatable-column>

          <!-- Columna Usuario -->
          <ngx-datatable-column name="Usuario" prop="usuario" [width]="200">
            <ng-template ngx-datatable-cell-template let-row="row">
              {{ row.usuario }}
            </ng-template>
          </ngx-datatable-column>

          <!-- Columna Fecha/Hora -->
          <ngx-datatable-column name="Fecha / Hora" prop="fecha" [width]="200">
            <ng-template ngx-datatable-cell-template let-row="row">
              {{ row.fecha | date:'yyyy-MM-dd HH:mm' }}
            </ng-template>
          </ngx-datatable-column>

          <!-- Columna Estado -->
          <ngx-datatable-column name="Estado" prop="estado" [width]="100">
            <ng-template ngx-datatable-cell-template let-row="row">
              <span class="status-pill" [ngClass]="{'status-active': row.estado === 'FINALIZADO', 'status-processing': row.estado === 'PROCESANDO'}">
                {{ row.estado }}
              </span>
            </ng-template>
          </ngx-datatable-column>

        </ngx-datatable>

      </div>
    </section>
  </div>
</div>
