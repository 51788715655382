import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { HistorialCargaService } from './historial-carga.service';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { timer } from 'rxjs';
import { SweetAlertService } from 'app/main/services/sweet-alert.service';
import { LocalStorageService } from 'app/main/service/local-storage.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { LoadingModalComponent } from '../loading-modal/loading-modal.component';
import { interval, Subscription } from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators'
import { HttpClient, HttpParams } from '@angular/common/http'

@Component({
  selector: 'app-historial-carga',
  templateUrl: './historial-carga.component.html',
  styleUrls: ['./historial-carga.component.scss'],
})
export class HistorialCargaComponent implements OnInit {
  // public
  estadoArchivo: string = 'PROCESANDO';
  public contentHeader: object;
  public data: any;
  public selectedOption: number = 20;
  public ColumnMode = ColumnMode;
  public isLastPage = false;
  public generalError: Error;
  public isLoadingPage = true;
  public allRowsRead = false;
  public lastHashKey = '';
  public lastRangeKey = '';
  public lastIndexRangeKey = '';
  public comercio_id: string = '';
  public hoveredDate: NgbDate | null = null;
  public fromDate: NgbDate | null;
  public toDate: NgbDate | null;
  public today = this.calendar.getToday();

  // filters
 // filters
 public selectStatus: any = [
  { name: 'Todo', value: '' },
  { name: 'Pendiente', value: 'PENDIENTE'},
  { name: 'En Verificacion', value: 'VERIFICADO'},
  { name: 'En Proceso', value: 'PROCESANDO' },
  { name: 'En Generación', value: 'PROCESANDO RESPUESTAS' },
  { name: 'Rechazado', value: 'RECHAZADO' },
  { name: 'Aprobado', value: 'APROBADO' },
];
  public selectedStatus = null;
  public searchValue = '';
  public newProcess: any;
  public administrationService: string = `${environment.apiAdminService}`

  // decorator
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('loadingModal') loadingModal!: LoadingModalComponent;

  // private
  private tempData = [];
  //private _unsubscribeAll: Subject<any>;
  public rows = [];
  public previousStatusFilter = '';
  public limitOptions = [{ key: '20', value: 20 }, { key: '30', value: 30 }, { key: '50', value: 50 }, { key: '75', value: 75 }];
  private pollingSubscription!: Subscription;

  /**
   * 
   * @param calendar 
   * @param formatter 
   * @param historialCargaService 
   * @param authService 
   */
  constructor(private http: HttpClient, private calendar: NgbCalendar, public formatter: NgbDateParserFormatter, 
    private historialCargaService: HistorialCargaService, private authService: AuthenticationService, 
    private alert: SweetAlertService, private localStorageService:LocalStorageService, private activateRoute:ActivatedRoute) {
    //this._unsubscribeAll = new Subject();
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  filterAll() {
    const val = this.searchValue.toLowerCase();
    let filterAllData = this.tempData;
    if (val !== '') {
      filterAllData = filterAllData.filter(function (d) {
        return (d.archivoId && d.archivoId.toLowerCase().indexOf(val) !== -1) || (d.nombreConvenio && d.nombreConvenio.toLowerCase().indexOf(val) !== -1) ||  (d.archivoOriginal && d.archivoOriginal.toLowerCase().indexOf(val) !== -1) || !val;
      });
    }

    let filterStatus = this.selectedStatus ? this.selectedStatus['value'] : '';

    if (filterStatus != '') filterStatus = filterStatus.toLowerCase();
    filterAllData = filterAllData.filter(row => {
      return (row.estado && row.estado.toLowerCase().indexOf(filterStatus) !== -1) || !filterStatus;
    });

    this.rows = filterAllData;
    return filterAllData;
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------
  /**
   * On init
   */
  ngOnInit(): void {

    localStorage.setItem("consultaTxs","false");

    if (localStorage.getItem("fromDate")) {
      const fromDate = JSON.parse(localStorage.getItem("fromDate"))
      this.fromDate = new NgbDate(fromDate.year, fromDate.month, fromDate.day);
    }

    if (localStorage.getItem("toDate") != "undefined" && localStorage.getItem("toDate") != null) {
      console.log("toDate:", localStorage.getItem("toDate"))
      const toDate = JSON.parse(localStorage.getItem("fromDateTxs"))
      this.toDate = new NgbDate(toDate.year, toDate.month, toDate.day);
    }

    this.contentHeader = {
      headerTitle: 'Procesos',
      actionButton: false,
      breadcrumb: {}
    };
    let attributes = this.authService.getPayload();
    this.newProcess = localStorage.getItem('newProcess');
    if (this.localStorageService.getItem('comercioSelected')) {
      this.comercio_id = this.localStorageService.getItem('comercioSelected');
    }
    

    const subs$ = timer(0, 180000);
    subs$.subscribe((d) => {
      this.isLoadingPage = true;
      this.getProcesses();
    })
  }

  getProcesses() {
    this.selectedStatus = null;
    this.isLoadingPage = true;
    this.historialCargaService.getTrxDataTable(this.localStorageService.getItem('comercioSelected'), this.selectedOption * 5, null, null, null, this.fromDate, this.toDate).subscribe(response => {
      this.data = response;
      //this.data.processes.sort((a, b) => a.fechaCargue == b.fechaCargue ? (a.horaProceso < b.horaProceso ? 1 : -1) : a.fechaCargue - b.fechaCargue);
      this.rows = this.data['processes'];
      if (!this.data['pagination']) {
        this.allRowsRead = true;
      } else {
        this.allRowsRead = false;
        this.table.offset = 0;
        this.lastHashKey = this.data['pagination']['startKeyComercioId'];
        this.lastRangeKey = this.data['pagination']['startKeyArchivoId'];
        this.lastIndexRangeKey = this.data['pagination']['startKeyFechaProceso'];
      }
      this.tempData = this.rows;
      this.isLoadingPage = false;
    }, err => {
      this.data = [];
      this.rows = this.data;
      this.tempData = this.rows;
      this.isLoadingPage = false;
    });
  }

  //filterDate
  filterDate() {

    console.log("fechaFin: " , this.fromDate)
    console.log("fechaInicio: " , this.toDate)

    localStorage.setItem("fromDate", JSON.stringify(this.fromDate));
    localStorage.setItem("toDate", JSON.stringify(this.toDate));

    this.isLoadingPage = true;
    this.rows = this.tempData = [];
    this.lastHashKey = this.lastIndexRangeKey = this.lastRangeKey = null;
    this.searchValue = ''; this.selectedStatus = null;
    this.allRowsRead = false;
    this.table.offset = 0;
    this.readNextRows();
  }

  readNextRows() {
    if (this.selectedStatus && this.selectedStatus['value'] !== '') return;
    if (this.searchValue && this.searchValue !== '') return;
    if (this.allRowsRead) return;
    this.historialCargaService.getTrxDataTable(this.localStorageService.getItem('comercioSelected'), this.selectedOption * 5, this.lastHashKey, this.lastRangeKey, this.lastIndexRangeKey, this.fromDate, this.toDate).subscribe(response => {
      this.data = response;
      //this.data.processes.sort((a, b) => a.fechaCargue == b.fechaCargue ? (a.horaProceso < b.horaProceso ? 1 : -1) : a.fechaCargue - b.fechaCargue);
      this.selectedStatus = null;
      let next_rows = this.data['processes'];
      if (!this.data['pagination']) {
        this.allRowsRead = true;
      } else {
        this.lastHashKey = this.data['pagination']['startKeyComercioId'];
        this.lastRangeKey = this.data['pagination']['startKeyArchivoId'];
        this.lastIndexRangeKey = this.data['pagination']['startKeyFechaProceso'];
      }

      if (next_rows)
        this.rows = [...this.rows, ...next_rows]
      this.tempData = this.rows;
      this.isLoadingPage = false;
      if (this.rows.length <= this.table.pageSize * (this.table.offset + 1)) {
        this.isLastPage = true;
      } else {
        this.isLastPage = false;
      }
    }, err => {
      this.data = [];
      this.rows = this.data;
      this.tempData = this.rows;
      this.isLoadingPage = false;
    });
  }

  canReadNextRows() {
    if (this.selectedStatus && this.selectedStatus['value'] !== '') return false;
    if (this.searchValue && this.searchValue !== '') return false;
    if (this.isLastPage && !this.allRowsRead) return true;
    return false;
  }

  getUrlFile(idComercio: string, nombreArchivo: string, archivoOriginal: string) {
    this.historialCargaService.getUrlFile(this.localStorageService.getItem('comercioSelected'), nombreArchivo, archivoOriginal).subscribe(
      data => {
        console.log(data);
        let urlArchivo = data['data']['url'];
        window.open(urlArchivo)
      }, err => {
        console.log(err);
        if (err.status === 404) {
          this.generalError = err.error;
          this.alert.alertNotification('error', this.generalError.message);
          return;
        }
        this.alert.alertNotification('error', 'Error al descargar el archivo.');
      }
    )
  }


  /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  onChange(pageInfo) {
    this.table.offset = pageInfo.page - 1;
    if (this.table.rowCount <= this.table.pageSize * pageInfo.page) {
      this.isLastPage = true;
      if (this.canReadNextRows())
        this.readNextRows();
    } else {
      this.isLastPage = false;
    }
  }

  changePageSize(event) {
    this.clearBasicFilters();
    this.table.pageSize = this.selectedOption;
    this.table.limit = this.table.pageSize;
    this.table.offset = 0;
    this.readNextRows();
  }

  clearBasicFilters() {
    this.rows = this.tempData;
    this.searchValue = '';
    this.selectedStatus = null;
    this.fromDate = null;
    this.toDate = null;
  }

  clearAllFilters() {
    
    localStorage.removeItem("fromDate");
    localStorage.removeItem("toDate");

    this.searchValue = ''; this.selectedStatus = null;
    this.fromDate = this.toDate = null;
    this.rows = this.tempData = [];
    this.allRowsRead = false;
    this.lastHashKey = this.lastIndexRangeKey = this.lastRangeKey = null;
    this.table.offset = 0;
    this.readNextRows();
  }

  /* Date Library*/
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  get disableBtnFecha() {
    if (!this.fromDate) return true;
    if (this.toDate) {
      if (this.fromDate.year * 10000 + this.fromDate.month * 100 + this.fromDate.day >
        this.toDate.year * 10000 + this.toDate.month * 100 + this.toDate.day) return true;
    }
    return false;
  }

  get enableClearFilter() {
    if (this.fromDate || this.toDate) return true;
    if (this.searchValue !== null && this.searchValue !== '') return true;
    if (this.selectedStatus && this.selectedStatus['value'] !== '') return true;
    return false;
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    if (input.length === 0) {
      this.fromDate = this.toDate = null;
      currentValue = null;
    }
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
  crearNovedad(comercioId: string, archivoId: string) {
    // Mostrar el modal de carga solo al iniciar el proceso
    this.loadingModal.isVisible = true;
    this.loadingModal.updateProgress('Generando Archivo', 0);

    // Parámetros de la solicitud inicial para crear el archivo
    const params = new HttpParams()
    .set('nombreArchivo', archivoId)
    .set('comercioId', comercioId);

    // Llamada inicial para crear el archivo
    this.http.get(`${this.historialCargaService.generatorUrlEndPoint}/novedades/file`, {
      params
    }).subscribe(
      () => {
        console.log('Función crearNovedad ejecutada exitosamente.');
        // Iniciar el polling para verificar el estado del archivo
        this.startLongPolling(params.get("nombreArchivo"));
      },
      (error) => {
       
        console.error('Error al ejecutar la función descargarArchivo', error.errorMessage);
        this.loadingModal.closeModal(); // Cerrar el modal en caso de error en el polling
        const errorMessage = error.error?.data 
        return this.alert.alertNotification('warning', errorMessage)
      }
    );
  }  

  startLongPolling(id: String) {
    console.log('Comienza el startPolling');
    console.log("id: " , id);
    const url = `${this.administrationService}/novedades/${id}`;

    // Inicia el polling cada 5 segundos
    this.pollingSubscription = interval(5000).pipe(
      switchMap(() => this.http.get<{ data: { estado: string } }>(url)),
      takeWhile(response => response.data.estado !== 'FINALIZADO', true) // Continuar mientras el estado no sea "FINALIZADO"
    ).subscribe(
      (response) => {
        // Actualizar estado con el valor de `estado` en la respuesta
        this.estadoArchivo = response.data.estado;
        console.log(`Estado actual del archivo: ${this.estadoArchivo}`);

        if (this.estadoArchivo === 'FINALIZADO') {
          console.log('Archivo listo para descargar.');
          this.loadingModal.updateProgress('Archivo finalizado', 100);
          this.pollingSubscription.unsubscribe(); // Detener el polling
        } else {
          this.loadingModal.updateProgress('Generando Archivo', 50);
          console.log('Archivo aún en proceso...');
        }
      },
      (error) => {
        console.error('Error al verificar el estado del archivo:', error);
        this.loadingModal.closeModal(); // Cerrar el modal en caso de error en el polling
        this.pollingSubscription.unsubscribe(); // Detener el polling en caso de error

      }
    );
  }
}


