import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { domainsInterceptor } from '../constants/constansInterceptor';

@Injectable()
export class HttpInterceptorInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    console.log("Interceptado: " + request.url);
  
    // Añadir withCredentials solo para GET y POST
    if (this.validateUrlIntercept(request.url) && (request.method === 'GET' || request.method === 'POST')) {
      const modifiedRequest = request.clone({
        withCredentials: true
      });
  
      return next.handle(modifiedRequest);
    }
  
    // Enviar las solicitudes PUT y DELETE sin withCredentials
    return next.handle(request);
  }
  

  private validateUrlIntercept(url: string): boolean {
    return domainsInterceptor.some(service => url.includes(service));
  }
}