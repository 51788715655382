import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterStateService {
  private filterState: any = {};

  setFilterState(state: any) {
    this.filterState = state;
  }

  getFilterState() {
    return this.filterState;
  }

  clearFilterState() {
    this.filterState = {};
  }
}
