import { Injectable } from '@angular/core';
import { HttpClient , HttpParams} from '@angular/common/http';
import { from, of, BehaviorSubject, Observable } from 'rxjs';
import { Auth } from 'aws-amplify';
import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { map, tap, catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { LocalStorageService } from 'app/main/service/local-storage.service';



@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  public currentUser: Observable<User>;
  public loggedIn: BehaviorSubject<boolean>;
  private currentUserSubject: BehaviorSubject<User>;
  public administrationService: string = `${environment.apiAdminService}`;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor( private route: ActivatedRoute,private router: Router,  private _http: HttpClient, private localStorageService:LocalStorageService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.loggedIn = new BehaviorSubject<boolean>(false);
  }

  public isAuthenticated(): Observable<boolean> {
    return from(Auth.currentAuthenticatedUser())
      .pipe(
        map(result => {
          this.loggedIn.next(true);
          return true;
        }),
        catchError(error => {
          this.loggedIn.next(false);
          return of(false);
        })
      );
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  get isAdmin() {
    return false;
    //return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  get isClient() {
    return false;
    //return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  forgotPassword(username: string) {
    return Auth.forgotPassword(username);
  }

  forgotPasswordSubmit(username: string, codigo: string, password: string) {
    return Auth.forgotPasswordSubmit(username, codigo, password);
  }

 /*  login(username: string, password: string) {
    return Auth.signIn(username, password);
  } */

  login(user: User) {

    this.currentUserSubject.next(user);
    return user;  
  }



  savePayload(atributos) {
    localStorage.setItem('payload', JSON.stringify(atributos));
  }

  getPayload() {
    return JSON.parse(localStorage.getItem('payload'));
  }

  completeNewPassword(user, password: string) {
    return Auth.completeNewPassword(user, password);
  }

  confirmSignIn(user, code:string) {
    return Auth.confirmSignIn(user, code,"SOFTWARE_TOKEN_MFA");
  }

  /**
   * User logout
   *
   */
  public logout() {
    this.prevenirNavegacionAtras();
  
    const payloadRaw = localStorage.getItem('payload');
    if (!payloadRaw) {
      console.error('No se encontró el payload en el localStorage');
      return;
    }
  
    const payload = JSON.parse(payloadRaw);
  
    const user = payload.usuario;
    const rol = payload.rol;
  
    const url = `${this.administrationService}/logout`;
    const params = new HttpParams()
      .set('user', user)
      .set('rol', rol);
  
    console.log("URL:", url + '?' + params.toString());
  
    this._http.post(url, {}, { params })
      .subscribe(
        response => console.log('Logout exitoso', response),
        error => console.error('Error en el logout', error)
      );
  
    from(Auth.signOut()).subscribe(
      () => {
        this.loggedIn.next(false);
        this.router.navigate(['/pages/authentication/login-v1']);
      },
      error => console.log(error)
    );
  }  
  
  // Método para prevenir la navegación hacia atrás
  private prevenirNavegacionAtras(): void {
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      Swal.fire({
        icon: 'warning',
        title: 'Debe iniciar sesión nuevamente',
        confirmButtonText: 'Aceptar'
      }).then(() => {
        this.router.navigate(['/pages/authentication/login-v1']);
      });
    };
  }
}
