import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SweetAlertService } from './sweet-alert.service';
import Swal from 'sweetalert2';
import { AuthenticationService } from 'app/auth/service';
import { BinesService } from '../dashboard/parametrización/parametrizacion.service';

@Injectable({
  providedIn: 'root'
})
export class InactividadService {

  private tiempoInactividad: number;
  private tiempoCierreSesion: number;
  private inactividadTimeoutId: any;
  private cierreSesionTimeoutId: any;
  private cierreSesionIniciado: boolean = false;  // Para asegurarnos de que el temporizador de cierre de sesión solo se inicie una vez

  constructor(
    private sweetAlertService: SweetAlertService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private parametrosService: BinesService
  ) { 
    this.obtenerTiemposConfigurados(); 
    this.obtenerTiempoCierreSesion();
  }

  // Verificar si la ruta actual es la de login
  esRutaExcluida(): boolean {
    const rutaActual = this.router.url;
    return rutaActual === '/pages/authentication/login-v1';
  }

  // Monitorear los eventos de actividad del usuario
  iniciarMonitoreoDeInteraccion(): void {
    window.addEventListener('mousemove', () => this.reiniciarTemporizadorInactividad());
    window.addEventListener('keydown', () => this.reiniciarTemporizadorInactividad());
    window.addEventListener('click', () => this.reiniciarTemporizadorInactividad());
    window.addEventListener('scroll', () => this.reiniciarTemporizadorInactividad());
  }

  // Obtener los tiempos configurados desde la base de datos
  obtenerTiemposConfigurados(): void {
    this.parametrosService.obtenerParametros().subscribe(parametros => {
      // Verifica que el valor de tiempoInactividad es válido antes de asignarlo
      if (parametros) {
        this.tiempoInactividad = parametros * 60000;
        console.log(`Tiempo de inactividad configurado: ${parametros} minutos`);
        // Iniciar el temporizador de inactividad por primera vez
        this.iniciarTemporizadorInactividad();
      } else {
        console.error("El valor de tiempoInactividad es inválido o no está definido.");
      }
    }, error => {
      console.error("Error al obtener los parámetros:", error);
    });
  }
  

  // Obtener tiempo de cierre de sesión desde otro endpoint
  obtenerTiempoCierreSesion(): void {
    this.parametrosService.obtenerTiempoCierresesion().subscribe(parametros => {
      this.tiempoCierreSesion = parametros * 60000;
      console.log(`Tiempo de cierre de sesión configurado: ${parametros} minutos`);

      // Solo iniciar el temporizador de cierre de sesión si no ha sido iniciado
      if (!this.cierreSesionIniciado) {
        this.iniciarTemporizadorCierreSesion();
        this.cierreSesionIniciado = true;  // Marcar que el temporizador ha sido iniciado
      }
    });
  }

  // Iniciar el temporizador de inactividad
  iniciarTemporizadorInactividad(): void {
    console.log(`Iniciando temporizador de inactividad por ${this.tiempoInactividad / 60000} minutos`);

    this.inactividadTimeoutId = setTimeout(() => {
      console.log('Tiempo de inactividad expirado. Mostrando diálogo de sesión.');
      this.mostrarDialogoSesion();
    }, this.tiempoInactividad);
  }

  // Iniciar el temporizador de cierre de sesión (solo se inicia una vez)
  iniciarTemporizadorCierreSesion(): void {
    console.log(`Iniciando temporizador de cierre de sesión por ${this.tiempoCierreSesion / 60000} minutos`);

    this.cierreSesionTimeoutId = setTimeout(() => {
      console.log('Tiempo de cierre de sesión expirado. Cerrando sesión automáticamente.');
      this.logoutAndRedirect();  // Cierra sesión automáticamente
    }, this.tiempoCierreSesion);
  }

  // Reiniciar solo el temporizador de inactividad, no el de cierre de sesión
  reiniciarTemporizadorInactividad(): void {
    if (this.inactividadTimeoutId) {
      clearTimeout(this.inactividadTimeoutId);  // Limpiar el temporizador anterior de inactividad
    }

    // Iniciar nuevo temporizador de inactividad
    this.iniciarTemporizadorInactividad();
  }

  // Mostrar diálogo al usuario para mantener o cerrar sesión
  mostrarDialogoSesion(): void {

    if (this.esRutaExcluida()) {
      console.log('Estamos en la página de login, no se inicia el temporizador de inactividad.');
      return;
    } else {

      this.sweetAlertService.alertAskToContinueSession().then((result) => {
        if (result.isConfirmed) {
          console.log('El usuario decidió mantener la sesión activa.');
          this.keepSessionActive();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log('El usuario decidió cerrar la sesión.');
          this.logoutAndRedirect();
        }
      });

    }
  }

  // Método para borrar cookies
  private borrarCookies(): void {
    document.cookie = 'jwtToken=; Max-Age=0; path=/; domain=localhost';
    document.cookie = 'SESSION=; Max-Age=0; path=/; domain=localhost';
    console.log('Cookies de sesión eliminadas.');
  }

  // Método para mantener la sesión activa
  private keepSessionActive(): void {
    console.log('Sesión renovada. Solo el temporizador de inactividad se ha reiniciado.');
    this.reiniciarTemporizadorInactividad();  // Solo reiniciar el temporizador de inactividad
  }

  // Método para cerrar la sesión y redirigir al usuario a la página de inicio de sesión
  private logoutAndRedirect(): void {
    this.prevenirNavegacionAtras();
    this.borrarCookies();
    this.authenticationService.logout();
    if (this.esRutaExcluida()) {
      console.log('Estamos en la página de login, no se inicia el temporizador de inactividad.');
      return;
    } else {
      // Mostrar el popup de SweetAlert indicando que la sesión se ha cerrado
      Swal.fire({
        icon: 'info',
        title: 'Sesión cerrada',
        text: 'Tu sesión ha expirado por inactividad o tiempo límite. Serás redirigido al inicio de sesión.',
        confirmButtonText: 'Aceptar'
      }).then(() => {
        // Redirigir al usuario a la página de login después de cerrar el popup
        this.router.navigate(['/pages/authentication/login-v1']);
      });
    }
  }

  // Método para prevenir la navegación hacia atrás
  private prevenirNavegacionAtras(): void {
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      Swal.fire({
        icon: 'warning',
        title: 'Debe iniciar sesión nuevamente',
        confirmButtonText: 'Aceptar'
      }).then(() => {
        this.router.navigate(['/pages/authentication/login-v1']);
      });
    };
  }
}
