import { Component, OnInit, ViewChild } from '@angular/core';
import { EstadisticaService } from './estadistica.service';
import { GeneralResponse } from 'app/main/models/GeneralResponse';
import { AuthenticationService } from 'app/auth/service';
import { CantidadArchivos, Estadisticas, TransaccionesTotales, ValoresTotales } from 'app/main/models/ResponseEstadisticas';
import { SweetAlertService } from 'app/main/service/sweet-alert.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { colors } from 'app/colors.const';
import { ApexChart, ApexDataLabels, ApexFill, ApexLegend, ApexMarkers, ApexNonAxisChartSeries, ApexPlotOptions, ApexResponsive, ApexStates, ApexStroke, ApexTooltip, ApexXAxis, ApexYAxis } from 'ng-apexcharts';
import { CoreConfigService } from '@core/services/config.service';
import { LocalStorageService } from 'app/main/service/local-storage.service';

export interface ChartOptions { 
  // Apex-Non-Axis-Chart-Series
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  fill: ApexFill;
  colors: string[];
  legend: ApexLegend;
  labels: any;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  markers: ApexMarkers[];
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  states: ApexStates;
}

@Component({
  selector: 'app-estadistica',
  templateUrl: './estadistica.component.html',
  styleUrls: ['./estadistica.component.scss']
})
export class EstadisticaComponent implements OnInit {

  @ViewChild('supportChartoptionsRef') supportChartoptionsRef: any;

  public contentHeader: object;
  public generalResponse: GeneralResponse;
  public comercioId: number;
  public estadisticas: Estadisticas;
  public transaccionesTotales: TransaccionesTotales;
  public montosTotales: ValoresTotales;
  public cantidadArchivos: CantidadArchivos;
  public sumCantidadArchivos: number = 0;
  public sumaTransacciones:number;
  public sumaMontosTotales:number;
  public valueMontosAprobados:number = 0;
  public valueMontosRechazados:number = 0;
  public showEstadistica: boolean = false;

  public hoveredDate: NgbDate | null = null;
  public fromDate: NgbDate | null;
  public toDate: NgbDate | null;
  public today = this.calendar.getToday();

  public data: any;
  public transaccionesAprobadas: number;
  public transaccionesRechazadas: number;
  public transaccionesProceso: number;

  public selectTime: any = [
    //{ name: '[Seleccione]', value: -1},
    { name: 'Hoy', value: 0 },
    { name: 'Ayer', value: 1 },
    { name: 'Hace 2 días', value: 2 },
    { name: 'Hace 3 días', value: 3 },
  ];

  public timeSelected = 0;

  public supportChartoptions: Partial<ChartOptions>;
  @ViewChild('supportChartRef') supportChartRef: any;
  public isMenuToggled = true;

  private $white = '#fff';
  private $textHeadingColor = '#5e5873';

  constructor(private authService: AuthenticationService, private estadisticaService: EstadisticaService, private alert: SweetAlertService, private formBuilder: FormBuilder,
    private calendar: NgbCalendar, public formatter: NgbDateParserFormatter,
    private localStorageService:LocalStorageService,
    private _coreConfigService: CoreConfigService) {

    this.supportChartoptions = {
      chart: {
        height: 290,
        type: 'radialBar',
        sparkline: {
          enabled: false
        }
      },
      plotOptions: {
        radialBar: {
          offsetY: 20,
          startAngle: -150,
          endAngle: 150,
          hollow: {
            size: '65%'
          },
          track: {
            background: this.$white,
            strokeWidth: '100%'
          },
          dataLabels: {
            name: {
              offsetY: -5,
              color: this.$textHeadingColor,
              fontSize: '1rem'
            },
            value: {
              offsetY: 15,
              color: this.$textHeadingColor,
              fontSize: '1.714rem'
            }
          }
        }
      },
      colors: [colors.light.success],
      fill: {
        type: 'gradient',
        gradient: {
          // Enabled: True,
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: [colors.solid.success],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      },
      stroke: {
        dashArray: 8
      },
      labels: ['Transacciones Aprobadas']
    };

  }

  ngOnInit(): void {
    if (this.localStorageService.getItem('comercioSelected')) {
      this.comercioId = this.localStorageService.getItem('comercioSelected');
    }

    this.data = {
      supportTracker: {
        series: [0]
      }
    };
  }

  initContentHeader() {
    this.contentHeader = {
      headerTitle: 'Estadisticas',
      actionButton: false,
      breadcrumb: {}
    };
  }

  filterDate() {
    let toDate = `${this.toDate.year}-${this.formatDate(this.toDate.month)}-${this.formatDate(this.toDate.day)}`;
    let fromDate = `${this.fromDate.year}-${this.formatDate(this.fromDate.month)}-${this.formatDate(this.fromDate.day)}`;

    this.estadisticaService.getEstadisticas(this.comercioId, fromDate, toDate).subscribe(data => {
      this.generalResponse = data as GeneralResponse;

      this.captureResponse(this.generalResponse);
    }, (error) => {
      if(error.status != 401){
        this.showEstadistica = false;

      // Ahora el mensaje del interceptor se propagará correctamente
      let errorMessage = error.error?.message || 'Error al obtener los datos de las estadísticas';
      
      this.alert.alertNotification('warning', errorMessage);
      }
    });
  }

  restarDiasALaFecha() {
    const fechaActual = new Date();
    fechaActual.setDate(fechaActual.getDate() - this.timeSelected);

    const año = fechaActual.getFullYear();
    const mes = (fechaActual.getMonth() + 1).toString().padStart(2, '0'); 
    const dia = fechaActual.getDate().toString().padStart(2, '0'); 

    let fechaFormateada = `${año}-${mes}-${dia}`;

    this.estadisticaService.getEstadisticas(this.comercioId, fechaFormateada, fechaFormateada).subscribe(data => {
      this.generalResponse = data as GeneralResponse;
      this.captureResponse(this.generalResponse);
    }, (error) => {
      if(error.status != 401){
        this.showEstadistica = false;

      // Ahora el mensaje del interceptor se propagará correctamente
      let errorMessage = error.error?.message || 'Error al obtener los datos de las estadísticas';
      
      this.alert.alertNotification('warning', errorMessage);
      }
    });
  }

  captureResponse(generalReponse: GeneralResponse) {
    this.estadisticas = generalReponse.data as Estadisticas;
    this.cantidadArchivos = this.estadisticas.cantidadArchivos;
    this.transaccionesTotales = this.estadisticas.transaccionesTotales;
    this.montosTotales = this.estadisticas.valoresTotales;

    this.transaccionesAprobadas = this.validateGeneralResponseData(generalReponse) ? (Number(this.transaccionesTotales.aprobadas) > 0 ) ? 
    (Number(this.transaccionesTotales.aprobadas) / Number(this.transaccionesTotales.cargadas)) * 100 : 0 : 0;

    this.transaccionesRechazadas = this.validateGeneralResponseData(generalReponse) ? ( Number(this.transaccionesTotales.rechazadas) > 0 ) ? 
    (Number(this.transaccionesTotales.rechazadas) / Number(this.transaccionesTotales.cargadas)) * 100 : 0 : 0;

    this.transaccionesProceso = this.validateGeneralResponseData(generalReponse) ? ( Number(this.transaccionesTotales.procesados) > 0 ) ? 
    (Number(this.transaccionesTotales.procesados) / Number(this.transaccionesTotales.cargadas)) * 100 : 0 : 0;
    
    this.valueMontosAprobados = this.validateGeneralResponseData(generalReponse) ? ( Number(this.montosTotales.aprobados) > 0 && Number(this.montosTotales.cargados) > 0 ) ? 
    (Number(this.montosTotales.aprobados) / Number(this.montosTotales.cargados)) * 100 : 0 : 0;

    this.valueMontosRechazados = this.validateGeneralResponseData(generalReponse) ? ( Number(this.montosTotales.rechazados) && Number(this.montosTotales.cargados) > 0) ? 
    (Number(this.montosTotales.rechazados) / Number(this.montosTotales.cargados)) * 100 : 0: 0;

    this.data.supportTracker.series = [this.validateGeneralResponseData(generalReponse) ? parseFloat(this.transaccionesAprobadas.toFixed(0)) : 0];
    console.log(this.transaccionesAprobadas);
  }

  validateGeneralResponseData(generalReponse:GeneralResponse) : boolean {
    return generalReponse.data != "No se encontraron resultados" ? true : false;
  }

  changePercentage(type:number){
    let value: number;
    let label: string;
    let colorsConfig: { light: string; solid: string };

    switch (type) {
      case 1:
        value = this.transaccionesAprobadas;
        label = 'Transacciones Aprobadas';
        colorsConfig = { light: colors.light.success, solid: colors.solid.success };
        break;
      case 2:
        value = this.transaccionesRechazadas;
        label = 'Transacciones Rechazadas';
        colorsConfig = { light: colors.light.danger, solid: colors.solid.danger };
        break;
      default:
        value = this.transaccionesProceso;
        label = 'Transacciones en Proceso';
        colorsConfig = { light: colors.light.primary, solid: colors.solid.primary };
        break;
    }

    this.supportChartoptions.colors = [colorsConfig.light];
    this.supportChartoptions.fill.gradient.gradientToColors = [colorsConfig.solid];
    this.data.supportTracker.series = [parseFloat(value.toFixed(0))];
    this.supportChartoptions.labels = [label];
  }

  formatDate(num: number): string {
    if (num < 10)
      return '0' + num;
    return num + '';
  }

  /* Date Library*/
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  get disableBtnFecha() {
    if (!this.fromDate) return true;
    if (this.toDate) {
      if (this.fromDate.year * 10000 + this.fromDate.month * 100 + this.fromDate.day >
        this.toDate.year * 10000 + this.toDate.month * 100 + this.toDate.day) return true;
    }
    return false;
  }

  get enableClearFilter() {
    if (this.fromDate || this.toDate) return true;
    return false;
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    if (input.length === 0) {
      this.fromDate = this.toDate = null;
      currentValue = null;
    }
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  ngAfterViewInit() {
    // Subscribe to core config changes
    this._coreConfigService.getConfig().subscribe(config => {
      // If Menu Collapsed Changes
      if (config.layout.menu.collapsed === true || config.layout.menu.collapsed === false) {
        setTimeout(() => {
          // Get Dynamic Width for Charts
          this.isMenuToggled = false;


          this.supportChartoptions.chart.width = this.supportChartRef?.nativeElement.offsetWidth;

        }, 1000);
      }
    });
  }
}
